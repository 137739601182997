import React from 'react'

import Layout from '../utils/Layout/Layout'
// import Header from '../components/Header/Header'
import PageHeader from '../components/PageHeader/PageHeader'
import PageAboutSection from '../components/PageAboutSection/PageAboutSection'
import ParticipateSection from '../components/ParticipateSection/ParticipateSection'
import EmailSuscribe from '../components/EmailSuscribe/EmailSuscribe'
import SEO from '../components/seo'

const AboutPage = () => (
  <Layout>
    <SEO title="About AfroFest" />
    <PageHeader heading="AfroFest Minnesota" paragraph="Welcome to page 2" />
    {/* <VideoSection
      title="Dumbo"
      src="https://www.youtube.com/embed/7NiYVoqBt-8"
    /> */}
    <PageAboutSection
      heading="About AfroFest"
      paragraph="AfroFest will be a celebration of culture, music, dance, food, art and community. It will take place in the 7th Place corridor in downtown St. Paul. Our goal is to create an annual event to celebrate culture, bridge differences, and build deep connections between residents, businesses, and community organizations in the downtown area."
      paragraph2="We will have fun and engaging activities for the whole family including; a play village area for kids, a stage with performances, several food tasting competitions, cultural exhibits and artifacts, community-involved art, and more. We are targeting families and people of all ages with a goal of attracting 500-700 over the course of the day."
      paragraph3="This cultural and family oriented event is a great opportunity for people of all cultures and backgrounds to come together to experience and celebrate great food, music, and wares from the African diaspora. AfroFest will showcase a wide array of artists, entertainers, and community leaders, and will feature a local marketplace with multiple entrepreneurs and nonprofit organizations. "
      li1="Partner at the ground level with an exciting event; positioned for growth over the next three years"
      li2="Display your organizations’ commitment to grassroots community building efforts"
      li3="Enjoy broad exposure to your brand at the event, through publicity around the event and event advertising and marketing efforts"
      li4="Network with local and international community members, vendors, businesses, services, and community groups"
    />
    <EmailSuscribe />
    <ParticipateSection />
  </Layout>
)

export default AboutPage
