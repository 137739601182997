import React from 'react'

import './PageHeader.scss'

const PageHeader = props => (
  <section className="container page-header" id="page-header">
    <div className="container">
      {/* <h1>{props.heading} </h1> */}
      {/* <p>{props.paragraph}</p> */}
    </div>
  </section>
)

export default PageHeader
