import React from 'react'

import './PageAboutSection.scss'

const PageAboutSection = props => (
  <section className="page-about-section" id="page-about-section">
    <div className="container">
      <h1>{props.heading} </h1>
      <p>{props.paragraph}</p>
      <p>{props.paragraph2}</p>

      <p>{props.paragraph3}</p>
      {/* <ul>
        <li>{props.li1}</li>
        <li>{props.li2}</li>
        <li>{props.li3}</li>
        <li>{props.li4}</li>
      </ul> */}
    </div>
  </section>
)

export default PageAboutSection
